import React, { useEffect, useState } from 'react'
import { DetailsWrapper, GridListWrapper } from '../../styles'
import { Button, ItemCard, SectionHeader, TOAST_TYPE, UploadButton } from '@base'
import { strings } from '@constants'
import { showLoader, showToast } from '@data/state/action'
import { useDispatch } from 'react-redux'
import { uploadCran } from '@data'
import { toggleFileViewer } from '@data/state/action/root'
import { Spacings } from '@styles'
import { displayAmount } from '@utils'

const FinancialHighlights = ({ orgData, financialData, update }) => {
	const dispatch = useDispatch()
	const [cranFile, setCranFile] = useState()

	useEffect(() => {
		setCranFile(financialData?.cran)
	}, [financialData])

	const itemData = [
		{
			label: strings('revenue'),
			value: financialData?.total_revenue ? displayAmount(financialData?.total_revenue) : '--',
		},
		{
			label: strings('current', 'ratio'),
			value: financialData?.current_ratio ? financialData?.current_ratio : '--',
		},
		{
			label: 'EBITDA',
			value: financialData?.ebitda ? financialData?.ebitda : '--',
		},
		{
			label: 'D/E',
			value: financialData?.debt_equity ? financialData?.debt_equity : '--',
		},

		{
			label: 'PAT',
			value: financialData?.pat ? displayAmount(financialData?.pat) : '--',
		},
		{
			label: strings('interest', 'coverage'),
			value: financialData?.interest_coverage ? financialData?.interest_coverage : '--',
		},
	]

	const uploadBuyerInvoiceHandler = (file) => {
		if (!file) return
		dispatch(showLoader(true))
		uploadCran(file, orgData?.organisation_id)
			.then((res) => {
				setCranFile(res?.file_path)
				dispatch(showToast(true, res?.response_message, { type: TOAST_TYPE.SUCCESS }))
				update()
			})
			.catch((err) => {
				dispatch(showToast(true, strings('document_upload_failed'), { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	const viewTCHandler = () => {
		dispatch(toggleFileViewer(true, { files: cranFile }))
	}

	return (
		<>
			<DetailsWrapper>
				<SectionHeader>
					{strings('financial', 'highlights')}
					<Button xs uppercase type='secondary' disabled={!cranFile} onClick={viewTCHandler} margin={`0 ${Spacings.SPACING_3B} 0 auto`}>
						{strings('view')}
					</Button>
					<UploadButton label={strings('upload', 'CRAN')} xs accept='pdf' type='primary' onChange={uploadBuyerInvoiceHandler} />
				</SectionHeader>
				<GridListWrapper itemPerRow={[1, 1, 1]}>
					<ItemCard medium data={itemData} key={`financial-highlights-item`} />
				</GridListWrapper>
			</DetailsWrapper>
		</>
	)
}

export default FinancialHighlights
