import styled from 'styled-components'
import { BaseWrapper, H6, H5, Button, Chip } from '@base'
import { Devices, Spacings, Text } from '@styles'

export const DetailsWrapper = styled(BaseWrapper)`
	padding: ${Spacings.SPACING_3B} ${Spacings.SPACING_4B};
	margin-bottom: ${Spacings.SPACING_3B};
`
export const EditIconWrapper = styled(Button)`
	padding: 0 ${Spacings.SPACING_2B};
	height: ${Spacings.SPACING_6B};
	width: ${Spacings.SPACING_6B};
	min-width: 0;
	margin: 0 ${Spacings.SPACING_1B};
	min-height: 0;
`

export const GridListWrapper = styled.div`
	display: grid;
	grid-template-columns: ${({ itemPerRow }) => `repeat(${itemPerRow?.[0] ?? 1}, 1fr)`};
	grid-gap: ${Spacings.SPACING_2B};

	@media (${Devices.tablet}) {
		grid-template-columns: ${({ itemPerRow }) => `repeat(${itemPerRow?.[1] ?? 2}, 1fr)`};
	}

	@media (${Devices.desktop}) {
		grid-template-columns: ${({ itemPerRow }) => `repeat(${itemPerRow?.[2] ?? 2}, 1fr)`};
	}
`
export const BankAccountCard = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: ${Spacings.SPACING_3B};
	background: ${({ theme }) => theme.palette.background.blueLightest};
	border-radius: ${Spacings.SPACING_2B};

	& > div {
		flex: 1;
	}
`

export const Label = styled(H6)`
	color: ${({ theme }) => theme.palette.text.label};
	line-height: ${Text.LINE_HEIGHT_MAX};
	margin: ${({ margin }) => margin ?? 0};
`
export const Value = styled(H5)`
	color: ${({ theme }) => theme.palette.text.dark};
	font-weight: ${Text.BOLD_600};
	line-height: ${Text.LINE_HEIGHT_MAX};
	margin-bottom: ${Spacings.SPACING_1B};
	&:last-of-type {
		margin-bottom: 0;
	}
`

export const NoDataText = styled(H6)`
	color: ${({ theme }) => theme.palette.text.label};
	font-weight: ${Text.BOLD_600};
	line-height: ${Text.LINE_HEIGHT_MAX};
	text-align: center;
`

export const RoleSectionWrapper = styled.div``
export const RoleTypeHeader = styled(H6)`
	margin-bottom: ${Spacings.SPACING_2B};
	color: ${({ theme }) => theme.palette.text.label};
`

export const RoleCard = styled.div`
	display: flex;
	justify-content: space-between;
	padding: ${Spacings.SPACING_2B} ${Spacings.SPACING_3B};
	align-items: center;
	background: ${({ theme }) => theme.palette.background.blueLightest};
	border-radius: ${Spacings.SPACING_2B};

	& > h6 {
		font-weight: ${({ unBold }) => (unBold ? Text.BOLD_500 : Text.BOLD_600)};
		margin-right: ${Spacings.SPACING_3B};
		line-height: ${Spacings.SPACING_7B};
	}

	${({ isCommodity }) => {
		if (isCommodity) {
			return `cursor: pointer;`
		}
	}}
`

export const SwitchCard = styled.div`
	display: flex;
	justify-content: space-between;
	padding: ${Spacings.SPACING_2B} ${Spacings.SPACING_3B};
	align-items: center;
	background: ${({ theme }) => theme.palette.background.blueLightest};
	border-radius: ${Spacings.SPACING_2B};

	& > h6 {
		font-weight: ${Text.BOLD_600};
		margin-right: ${Spacings.SPACING_3B};
		line-height: ${Spacings.SPACING_7B};
	}
`

export const GSTCard = styled.div`
	padding: ${Spacings.SPACING_2B} ${Spacings.SPACING_3B};
	background: ${({ theme }) => theme.palette.background.blueLightest};
	border-radius: ${Spacings.SPACING_2B};

	& > div:nth-child(2) {
		margin-top: ${Spacings.SPACING_2B};
	}
`

export const Address = styled(H5)`
	color: ${({ theme }) => theme.palette.text.dark};
	line-height: ${Text.LINE_HEIGHT_MAX};
`

export const ButtonWrapper = styled.div`
	display: flex;
	gap:${Spacings.SPACING_2B};
`

export const TextValue = styled(H5)`
	line-height: ${Text.LINE_HEIGHT_MAX};
	margin-top: ${Spacings.SPACING_2};
	font-weight: ${Text.BOLD_600};
	color: ${({ theme }) => theme.palette.text.black};
`

export const Status = styled(Value)`
	cursor: ${({ clickable }) => (clickable ? 'pointer' : 'initial')};
	color: ${({ status, theme }) => {
		switch (status) {
			case 'verified':
				return theme.palette.text.green
			case 'unverified':
				return theme.palette.text.red
			case 'requested':
				return theme.palette.text.blue
			default:
				return theme.palette.text.red
		}
	}};
	${({ small }) => {
		if (small) {
			return `font-size: ${Text.SMALL};`
		}
	}}
`

export const EditDetailWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: ${Spacings.SPACING_2B};
	& > div {
		flex-grow: 1;
	}

	& > div:nth-child(2) {
		flex-grow: 0;
		margin-left: ${Spacings.SPACING_2B};
	}
`

export const CurrentRolePill = styled(H6)`
	padding: ${Spacings.SPACING_2B} ${Spacings.SPACING_3B};
	background: ${({ theme }) => theme.palette.background.pastelBeige};
	color: ${({ theme }) => theme.palette.text.dark};
	border-radius: ${Spacings.SPACING_10B};
`
export const BannerText = styled.div`
	display: flex;
	align-items: center;
	flex-grow: 1;
`

export const BannerWrapper = styled.div`
	margin-bottom: ${Spacings.SPACING_3B};
`
export const OrgRole = styled.div`
	font-size: ${Text.MICRO_SMALL};
	font-weight: ${Text.BOLD_600};
	padding: ${Spacings.SPACING_2} ${Spacings.SPACING_6};
	margin: ${({ margin }) => margin};
	margin-bottom: ${Spacings.SPACING_2};
	background: ${({ theme, pending }) => (pending ? theme.palette.background.yellowLight : theme.palette.background.greenLight)};
	border-radius: ${Spacings.SPACING_2B};
	&:last-of-type {
		margin-bottom: 0;
	}
`

export const FieldWrapper = styled.div`
	margin-bottom: ${Spacings.SPACING_2B};
`
export const BiFieldWrapper = styled(FieldWrapper)`
	display: grid;
	grid-template-columns: 1fr 6fr;
	column-gap: ${Spacings.SPACING_2B};
`

export const GridWrapper = styled.div`
	display: flex;
	grid-gap: ${Spacings.SPACING_2B};
	flex-direction: column;
	@media ${Devices.mobile} {
		flex-direction: row;
	}
`
export const GridCardList = styled.div`
	display: flex;
	grid-gap: ${Spacings.SPACING_2B};
	flex-wrap: wrap;
`

export const MenuIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	& > svg {
		font-size: ${Text.MEDIUM};
	}
`

export const LabelWrapper = styled.div`
	& > h6 {
		display: flex;
		align-items: baseline;
	}
`

export const ChipWapper = styled(Chip)`
	background: ${({ theme }) => theme.palette.text.grey};
	color: ${({ theme }) => theme.palette.text.darkblack};
`
export const PillWapper = styled.div`
	display: flex;
	flex-wrap: wrap;
`