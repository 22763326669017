import React, { useEffect, useMemo, useState } from 'react'
import { DetailsWrapper, GridListWrapper } from '../../styles'
import { FloatingButton, PageLevelTableWrapper, SectionHeader, Table } from '@base'
import { DRAWER_TYPES, strings } from '@constants'
import { displayAmount, showDateFormat } from '@utils'
import { getCreditLimitByOrdId } from '@data/conn/get/creditLimit'
import { CallMerge } from '@material-ui/icons'
import { toggleDrawer } from '@data/state/action/root'
import { useParams } from 'react-router'
import { Spacings } from '@styles'
import { useDispatch } from 'react-redux'

const CreditLimitList = ({ orgData, forceUpdate, update }) => {
	const { orgId } = useParams()
	const dispatch = useDispatch()

	const [creditLimitList, setCreditLimitList] = useState([])

	useEffect(() => {
		orgData?.organisation_id &&
			getCreditLimitByOrdId(orgData?.organisation_id)
				.then((response) => {
					setCreditLimitList(response)
				})
				.catch((error) => {
					console.log(error)
				})
	}, [orgData, forceUpdate])

	const columns = useMemo(() => {
		return [
			{
				label: strings('status'),
				key: (item) => {
					return item?.status ? item?.status : '-'
				},
				mobile: true,
			},
			{
				label: strings('amount'),
				key: (item) => {
					return item?.amount ? displayAmount(item?.amount) : '-'
				},
				mobile: true,
			},
			{
				label: strings('sanction_date'),
				key: (item) => {
					return item?.sanction_date ? showDateFormat(new Date(item?.sanction_date)) : '-'
				},
				mobile: true,
			},
			{
				label: strings('expiry_date'),
				key: (item) => {
					return item?.expiry_date ? showDateFormat(new Date(item?.expiry_date)) : '-'
				},
				mobile: true,
			},
		]
	}, [])

	const addCreditLimitHandler = () => {
		dispatch(
			toggleDrawer(true, DRAWER_TYPES.ADD_CREDIT_LIMIT, {
				label: strings(['add', 'credit', 'limit']),
				orgId,
				userData: orgData?.client_details,
				outletUpdate: update,
			})
		)
	}

	return (
		<div>
			<DetailsWrapper>
				<SectionHeader>
					{strings(['credit', 'details'])}
					<FloatingButton
						desktopLabel={strings(['add'])}
						mobileLabel={<CallMerge fontSize='small' />}
						onClick={addCreditLimitHandler}
						small
						notFixed
						margin={`0 ${Spacings.SPACING_1B}`}
					/>
				</SectionHeader>
				<GridListWrapper itemPerRow={[1, 1, 1]}>
					<PageLevelTableWrapper>
						<Table fullHeight data={creditLimitList} columns={columns} page={0} hideFooter rowClickable={false} />
					</PageLevelTableWrapper>
				</GridListWrapper>
			</DetailsWrapper>
		</div>
	)
}

export default CreditLimitList
