import { memo, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import {
	Close,
	Edit,
	CheckCircleOutline,
	PanoramaFishEye,
	LocalShipping,
	LocalShippingOutlined,
	OpenInNew,
	SubdirectoryArrowLeft,
	CropSquareSharp,
	Delete,
} from '@material-ui/icons'
import {
	updateInvoiceV2,
	uploadDocument,
	editOrder,
	addTrip,
	updateInvoiceTracker,
	closeTrip,
	deleteOrderDocumentApi,
	uploadBuyerInvoiceDocumentsApi,
	uploadExportDocumentsApi,
} from '@data'
import { showLoader, showToast } from '@data/state/action'
import { toggleDrawer, toggleFileViewer, updateDrawerMeta } from '@data/state/action/root'

import { Spacings, Text, Devices } from '@styles'
import {
	DrawerBodyWrapper,
	Input,
	SectionHeader,
	Button,
	DocumentDownload,
	H6,
	UploadButton,
	Select,
	Calendar,
	TOAST_TYPE,
	ImageHandler,
	UploadMultipleFileButton,
} from '@base'
import { MB_ROLES, documentInvoiceType, strings, docTypeAll, OTHER_CHARGES_INVOICE } from '@constants'
import { EnquiryItem } from '@data/model'
import { showDateFormat, displayAmount } from '@utils'

const CustomSectionHeader = styled(SectionHeader)`
	margin-top: ${Spacings.SPACING_6B};
`

const FormWrapper = styled.div`
	display: flex;
	flex-direction: column;
`

const ButtonTrip = styled(Button)`
	min-width: ${Spacings.SPACING_12B};
`

const CancelPresentationIcon = styled(Close)``
const EditIcon = styled(Edit)``
const CheckCircleOutlineIcon = styled(CheckCircleOutline)``
const LocalShippingIcon = styled(LocalShipping)`
	color: ${({ theme }) => theme.palette.background.white};
`
const LocalShippingOutlinedIcon = styled(LocalShippingOutlined)`
	color: ${({ theme }) => theme.palette.background.white};
`
const OpenInNewIcon = styled(OpenInNew)`
	cursor: pointer;
	&: hover {
		color: ${({ theme }) => theme.palette.background.blueLight};
	}
`

const FormItemWrapper = styled.div`
	margin-top: ${Spacings.SPACING_2B};
	display: flex;
	flex-direction: row;
	align-items: center;

	${({ isMargin }) => {
		if (isMargin) {
			return css`
				div:nth-child(2) {
					margin-left: 5px;
				}
				div:nth-child(3) {
					margin-left: 5px;
				}
			`
		}
	}}

	&:first-of-type {
		margin-top: 0;
	}
	& > div {
		width: 100%;
	}

	${({ IsRejectWieght }) => {
		if (IsRejectWieght) {
			return css`
				& > div {
					flex: 4;
				}
				div:nth-child(2) {
					flex: 4;
				}
				div:nth-child(3) {
					flex: 4;
				}
			`
		}
	}};

	${({ IsFlex }) => {
		if (IsFlex) {
			return css`
				& > div {
					flex: 9;
				}
				div:nth-child(2) {
					flex: 3;
				}
			`
		}
	}};

	${({ isStock }) => {
		if (isStock) {
			return css`
				justify-content: space-between;
			`
		}
	}};
	${({ isFlexCol }) => {
		if (isFlexCol) {
			return css`
				flex-direction: column;
				@media ${Devices.mobile} {
					flex-direction: row;
				}
			`
		}
	}};
`

const ChargesWrapper = styled(FormItemWrapper)`
	flex-direction: column;
	background: ${({ theme }) => theme.palette.background.blueLightest};
	padding: ${Spacings.SPACING_3B} ${Spacings.SPACING_3B};
	& div:last-child {
		margin-bottom: 0;
	}
`

const ExportFormItemWrapper = styled.div`
	margin-top: ${Spacings.SPACING_2B};
	display: flex;
	flex-direction: row;
	flex: 1;
	justify-content: space-between;
	align-items: center;
	& > div {
		width: 100%;
	}
`

const ExportDocFormItemWrapper = styled.div`
	height: ${Spacings.SPACING_10B};
	display: flex;
	flex: 1;
	flex-direction: row;
	align-items: center;
`

const ExportDocName = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	margin-bottom: ${Spacings.SPACING_1B};
	font-size: ${Text.SMALL};
`
const ExportDocItem = styled.div`
	justify-content: right;
	display: flex;
	flex-direction: row;
	flex: 1;
`

const ExportDownloadIconWrapper = styled.div`
	padding: ${Spacings.SPACING_1B};
	width: fit-content;
	background: ${({ theme }) => theme.palette.background.blueLightest};
	align-items: center;
	& > svg {
		font-size: 1.2rem;
	}
	border-radius: ${Spacings.SPACING_2B};
`
const ActionButton = styled(Button)`
	margin-left: ${({ noAutoLeft }) => (noAutoLeft ? 'inherit' : 'auto')};
`

const DocUploadButton = styled(UploadButton)`
	margin-left: auto;
`
const ExportDocUploadButton = styled(UploadButton)`
	margin-left: auto;
`
const UploadMultipleFile = styled(UploadMultipleFileButton)`
	margin-left: auto;
`
const DocumentsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
`
const DownloadIconWrapper = styled.div`
	padding: ${Spacings.SPACING_3B};
	background: ${({ theme }) => theme.palette.background.blueLightest};
	align-items: center;
	justify-content: center;
	margin-right: ${Spacings.SPACING_4B};
	width: fit-content;
	border-radius: ${Spacings.SPACING_2B};
`

const Message = styled(H6)`
	flex-grow: 1;
	flex-basis: 100%;
	margin-top: ${Spacings.SPACING_1B};
	margin-bottom: ${Spacings.SPACING_3B};
	color: ${({ theme }) => theme.palette.text.label};
	font-weight: ${Text.BOLD_600};
`

const SubSectionHeading = styled(H6)`
	margin-top: ${Spacings.SPACING_4B};
	margin-bottom: ${Spacings.SPACING_2B};
`

// const TopSectionHeader = styled(SectionHeader)`
// 	justify-content: space-between;
// `

const VehicleTrackingWapper = styled.div`
	display: flex;
	justify-content: flex-start;
	@media ${Devices.mobile} {
		justify-content: center;
	}
`
const ProgressBarContainer = styled.div`
	height: 21px;
	background-color: ${({ theme }) => theme.palette.background.main};
	border-radius: 50px;
	display: flex;
	justify-content: space-between;
	color: ${({ theme }) => theme.palette.background.vehicleGreen};
`
const FillerWapper = styled.div`
	height: 100%;
	width: 0%;
	${({ isCompleted }) => {
		if (isCompleted) {
			return css`
				width: ${isCompleted}%;
			`
		}
	}};
	background-color: ${({ theme }) => theme.palette.background.vehicleGreen};
	transition: width 1s ease-in-out;
	border-radius: inherit;
	text-align: right;
`
const LabelWapper = styled.span`
	color: white;
	font-weight: bold;
	margin: 0px 4px;
	${({ isCompleted }) => {
		if (isCompleted === 100) {
			return css`
				margin: 0px;
			`
		}
	}};
`
const Container = styled.div`
	display: flex;
	justify-content: space-between;
`
const ContainerText = styled(H6)`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: ${Spacings.SPACING_2B};
	margin-bottom: ${Spacings.SPACING_2B};
	& > span {
		margin-top: 2px;
	}
`

const ChargeListWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: ${Spacings.SPACING_2B};
	& > div {
		width: 100%;
	}
	font-size: ${Text.MEDIUM};
	gap: ${Spacings.SPACING_3B};
	& > div:nth-child(2) {
		color: ${({ theme }) => theme.palette.text.blackGrey};
	}
`

const deriveStateFromData = (data, order, supplierPoNumber) => {
	const _state = {
		invoice_amount: {
			label: strings('invoice', 'amount'),
			value: '',
			disabled: false,
			required: true,
			dirty: false,
			placeholder: strings(['enter', 'invoice', 'amount']),
		},
		invoice_number: {
			label: strings('invoice', 'number'),
			value: '',
			disabled: false,
			required: true,
			dirty: false,
			placeholder: strings(['enter', 'invoice', 'number']),
		},
		invoice_date: {
			label: strings('invoice', 'date'),
			value: '',
			disabled: false,
			dirty: false,
			placeholder: strings(['enter', 'invoice', 'date']),
		},
		delivery_status: {
			label: strings('delivery', 'status'),
			value: { status: '-' },
			disabled: false,
			placeholder: strings(['enter', 'delivery', 'status']),
			data: [{ status: '-' }, { status: 'LOADED' }, { status: 'EN ROUTE' }, { status: 'DELIVERED' }],
			displayKey: 'status',
			required: false,
		},
		gps_link: {
			label: strings('tracking_link'),
			value: '',
			disabled: false,
			dirty: false,
			placeholder: strings('enter', 'tracking_link'),
			required: false,
		},
		order_items: [],
		driver_number: {
			label: strings('driver', 'number'),
			value: '',
			disabled: false,
			required: false,
			dirty: false,
			placeholder: strings(['enter', 'driver', 'number']),
		},
		vehicle_number: {
			label: strings('vehicle', 'number'),
			value: '',
			disabled: false,
			required: false,
			dirty: false,
			placeholder: strings(['enter', 'vehicle', 'number']),
		},
		rejected_weight: {
			label: strings('rejected', 'weight'),
			value: '',
			disabled: false,
			required: false,
			dirty: false,
			placeholder: strings(['enter', 'rejected', 'weight']),
		},
		other_charges: {
			value: {},
		},
	}

	if (!data) return _state

	_state.invoice_amount.value = data?.invoice_amount
	_state.invoice_number.value = data?.original_invoice_number || data?.invoice_number
	_state.invoice_date.value = data?.invoice_date
	_state.gps_link.value = data?.gps_link
	_state.driver_number.value = data?.driver_number
	_state.vehicle_number.value = data?.vehicle_number
	_state.delivery_status.value = { status: data?.delivery_status || '-' }
	_state.rejected_weight.value = data?.rejected_weight

	// other charges
	if (data?.freight_charge) _state.other_charges.value = { ..._state.other_charges.value, freight_charge: data?.freight_charge }
	if (data?.cutting_charge) _state.other_charges.value = { ..._state.other_charges.value, cutting_charge: data?.cutting_charge }
	if (data?.pickling_charge) _state.other_charges.value = { ..._state.other_charges.value, pickling_charge: data?.pickling_charge }
	if (data?.insurance) _state.other_charges.value = { ..._state.other_charges.value, insurance: data?.insurance }
	if (data?.packaging_charge) _state.other_charges.value = { ..._state.other_charges.value, packaging_charge: data?.packaging_charge }
	if (data?.slitting_charge) _state.other_charges.value = { ..._state.other_charges.value, slitting_charge: data?.slitting_charge }
	if (data?.other_charge) _state.other_charges.value = { ..._state.other_charges.value, other_charge: data?.other_charge }
	if (data?.packing_charge) _state.other_charges.value = { ..._state.other_charges.value, packing_charge: data?.packing_charge }
	if (data?.pmi_charge) _state.other_charges.value = { ..._state.other_charges.value, pmi_charge: data?.pmi_charge }
	if (data?.transport_detention_charge)
		_state.other_charges.value = { ..._state.other_charges.value, transport_detention_charge: data?.transport_detention_charge }

	if (!data.order_items) {
		let orderItems
		if (supplierPoNumber) {
			const supplier = order?.supplier_list.find((_s) => _s?.supplier_po_number === supplierPoNumber)
			orderItems = supplier.order_items.map((item) => {
				return { ...item, ordered_weight: item?.allotted_weight }
			})
		} else {
			orderItems = order?.buyer_list?.[0].order_items
		}
		_state.order_items = orderItems.map((_o) => new EnquiryItem(_o).getOrderItemDetails())
	} else {
		_state.order_items = data.order_items
	}
	return _state
}

const AdminEditInvoice = memo(({ order, update, supplierPoNumber, manageOrderType = 'enquiry' }) => {
	const dispatch = useDispatch()
	const [formState, setFormState] = useState(deriveStateFromData())
	const invoiceData = useSelector((state) => state.getIn(['root', 'drawerTypeMeta', 'invoiceData']))
	const userData = useSelector((state) => state.getIn(['user', 'user']))
	const [docType, setDocType] = useState('')
	const [otherChargeValue, setOtherChargeValue] = useState()
	const [otherChargeType, setOtherChargetype] = useState({})

	useEffect(() => {
		setFormState((_) => deriveStateFromData(invoiceData, order, supplierPoNumber))
	}, [invoiceData, order])

	useEffect(() => {
		dispatch(
			updateDrawerMeta({
				label: (
					<>
						{strings('invoice', 'details')}
						<ActionButton
							xs
							uppercase
							type='secondary'
							onClick={() => {
								if (invoiceData?.invoice_document) {
									viewInvoiceHandler(invoiceData?.invoice_document)
								}
							}}
						>
							{strings('view', 'invoice')}
						</ActionButton>
					</>
				),
			})
		)
	}, [dispatch, invoiceData, docType])

	const updateState = (key, updates) =>
		setFormState((state) => ({
			...state,
			[key]: {
				...state[key],
				...updates,
			},
		}))

	const onChange = (key) => (value) => updateState(key, { value, dirty: true })

	const onChangeOrderItem = (key, productId) => (value) => {
		setFormState((state) => {
			const _orderItems = state?.order_items?.map((_o) => {
				if (_o.product_id === productId) {
					if (key === 'serviced_weight') _o.serviced_weight = value
					if (key === 'price_per_kg') _o.price_per_kg = value
				}
				return _o
			})

			return {
				...state,
				order_items: _orderItems,
			}
		})
	}

	const disabledUpdateButton = useMemo(() => {
		if (!invoiceData) return true
		const nonDisabledEntries = Object.entries(formState).filter(([key, val]) => !val.disabled)
		let reg = /^[\d]+(?:\.\d{1,3})?$/

		return (
			nonDisabledEntries.some(([key, val]) => val.required && !val.value) ||
			nonDisabledEntries.every(([key, val]) => val.value === invoiceData[key]) ||
			formState.order_items?.find((item) => item?.serviced_weight && !reg.test(item?.serviced_weight))
		)
	}, [formState, invoiceData])

	const checkInvoiceHandler = () => {
		let invoiceList
		if (supplierPoNumber) {
			const supplier = order?.supplier_list.find((_s) => _s?.supplier_po_number === supplierPoNumber)
			invoiceList = supplier.supplier_invoices
		} else {
			invoiceList = order?.buyer_list?.[0].buyer_invoices
		}
		return invoiceList
			?.filter((item) => item?.invoice_number !== invoiceData.invoice_number)
			.some((item2) => item2.original_invoice_number === formState.invoice_number.value)
	}

	const updateInvoiceInfo = () => {
		if (disabledUpdateButton) return
		if (checkInvoiceHandler()) return dispatch(showToast(true, strings('invoice_same_doc_num'), { type: TOAST_TYPE.ERROR }))

		dispatch(showLoader(true))

		updateInvoiceV2(
			{
				invoice_amount: formState.invoice_amount.value,
				invoice_date: formState.invoice_date.value ?? new Date().getTime(),
				invoice_number: invoiceData.invoice_number,
				order_items: formState.order_items,
				original_invoice_number: formState.invoice_number.value,
				gps_link: formState.gps_link.value,
				delivery_status: formState.delivery_status.value?.status === '-' ? null : formState.delivery_status.value?.status || null,
				driver_number: formState.driver_number.value,
				vehicle_number: formState.vehicle_number.value,
				delivery_date: !invoiceData?.delivery_date && formState.delivery_status.value?.status === 'DELIVERED' ? new Date().getTime() : null,
				rejected_weight: formState.rejected_weight.value,
				...formState.other_charges.value,
			},
			order?.order_id
		)
			.then((response) => {
				let updatedInvoiceData
				if (supplierPoNumber) {
					const getSupplierPoNumber = response.data?.supplier_list?.find((item) => item?.supplier_po_number === supplierPoNumber)
					updatedInvoiceData = getSupplierPoNumber.supplier_invoices.find((_i) => _i.invoice_number === invoiceData.invoice_number)
				} else {
					updatedInvoiceData = response.data?.buyer_list[0].buyer_invoices.find((_i) => _i.invoice_number === invoiceData.invoice_number)
				}

				dispatch(showToast(true, strings('msg_invoice_details_updated'), { type: TOAST_TYPE.SUCCESS }))
				dispatch(
					updateDrawerMeta({
						order: response.data,
						invoiceData: updatedInvoiceData,
					})
				)
				update()
			})
			.catch((err) => {
				dispatch(showToast(true, strings('msg_failed_to_update_invoice_details'), { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
		dispatch(toggleDrawer(false))
	}
	const uploadExportDocuments = (doctype, files) => {
		if (files.length < 1) return
		dispatch(showLoader(true))
		const getPath = Array.from(files).map((f) => f)
		uploadExportDocumentsApi(doctype, getPath.length > 0 ? getPath : [files], invoiceData.invoice_number, order.order_id)
			.then((response) => {
				dispatch(
					updateDrawerMeta({
						invoiceData: {
							...response.data,
						},
					})
				)
				dispatch(showToast(true, strings('msg_document_uploaded'), { type: TOAST_TYPE.SUCCESS }))
				update()
			})
			.catch((err) => {
				dispatch(showToast(true, `${strings('document_upload_failed')} ${err}`, { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
		setDocType('')
	}
	const uploadMultipleDoc = (files, type, key) => {
		const invoiceType = supplierPoNumber ? 'supplier' : 'buyer'
		if (files.length < 1) return
		dispatch(showLoader(true))
		const getPath = Array.from(files).map((f) => f)
		const updates = {}
		uploadBuyerInvoiceDocumentsApi(
			invoiceData.invoice_number,
			getPath.length > 0 ? getPath : [files],
			order.order_id,
			documentInvoiceType[type],
			invoiceType
		)
			.then((response) => {
				updates[key] = ['tc_received', 'dkt_documents'].includes(key)
					? [...(invoiceData[key] ?? []), ...response.file_paths]
					: response.file_paths[0]
				dispatch(
					updateDrawerMeta({
						invoiceData: {
							...invoiceData,
							...updates,
						},
					})
				)
				update()
			})
			.catch((err) => {
				dispatch(showToast(true, `${strings('document_upload_failed')} ${err}`, { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	const updateDocument = (key, link) => {
		if (key === 'tc') {
			invoiceData.tc_received = invoiceData.tc_received.filter((item) => item !== link)
		} else if (key === 'bilti') {
			invoiceData.bilty_document = null
		} else if (key === 'ewaybill') {
			invoiceData.eway_bill_document = null
		} else if (key === 'dkt') {
			invoiceData.dkt_documents = invoiceData.dkt_documents.filter((item) => item !== link)
		} else if (key === 'delivery_receipt') {
			invoiceData.delivery_receipt = null
		}
		updateOrder()
	}

	const updateOrder = () => {
		dispatch(showLoader(true))
		editOrder(order)
			.then((response) => {
				dispatch(showToast(true, strings('msg_order_editted'), { type: TOAST_TYPE.SUCCESS }))
				dispatch(
					updateDrawerMeta({
						invoiceData: {
							...invoiceData,
						},
					})
				)
				update()
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	let NextNum = 0
	const _uploadstockHandler = async (getPaths, uploadedStockPhotos) => {
		if (NextNum < getPaths.length) {
			dispatch(showLoader(true))
			return await uploadDocument(getPaths[NextNum], invoiceData?.invoice_number, order.order_id, 'stockPhotos', 'supplier')
				.then((response) => {
					if (response?.file_path) {
						NextNum += 1
						uploadedStockPhotos.push(response?.file_path)
						return _uploadstockHandler(getPaths, uploadedStockPhotos)
					}
				})
				.catch((e) => console.log(e))
				.finally(() => {
					dispatch(showLoader(false))
				})
		}
		return uploadedStockPhotos
	}

	const uploadStockPhotoHandler = async (files) => {
		const getPaths = Array.from(files).map((f) => f)
		let uploadedStockPhotos = []
		let _stockPhotos = await _uploadstockHandler(getPaths, uploadedStockPhotos)
		if (_stockPhotos.length > 0) {
			const product_photos = invoiceData?.product_photos ? [..._stockPhotos, ...invoiceData?.product_photos] : [..._stockPhotos]
			dispatch(
				updateDrawerMeta({
					invoiceData: {
						...invoiceData,
						product_photos,
					},
				})
			)
			update()
		}
	}

	const startVehicleTrackingHandler = () => {
		dispatch(showLoader(true))
		addTrip(order.order_id, invoiceData.invoice_number)
			.then((response) => {
				dispatch(showToast(true, strings('add_trip_success'), { type: TOAST_TYPE.SUCCESS }))
				dispatch(
					updateDrawerMeta({
						invoiceData: {
							...response,
						},
					})
				)
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				update()
				dispatch(showLoader(false))
			})
	}

	const updateVehicleTrackingHandler = () => {
		dispatch(showLoader(true))
		updateInvoiceTracker(
			formState.driver_number?.value,
			invoiceData.invoice_number,
			invoiceData?.trip_id,
			order.order_id,
			formState.vehicle_number.value
		)
			.then((response) => {
				dispatch(showToast(true, strings('update_trip_successfully'), { type: TOAST_TYPE.SUCCESS }))
				dispatch(
					updateDrawerMeta({
						invoiceData: {
							...response,
						},
					})
				)
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				update()
				dispatch(showLoader(false))
			})
	}

	const stopVehicleTrackingHandler = () => {
		closeTrip(order.order_id, invoiceData.invoice_number)
			.then((response) => {
				dispatch(showToast(true, strings('stop_trip_successfully'), { type: TOAST_TYPE.SUCCESS }))
				dispatch(
					updateDrawerMeta({
						invoiceData: {
							...response,
						},
					})
				)
				update()
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	const TrackingHandler = (e, url) => {
		e.stopPropagation()
		url && window.open(url, '_blank')
	}

	const _deliverStatus = useMemo(() => {
		if (!invoiceData) return true
		switch (invoiceData?.delivery_status) {
			case 'LOADED':
				return { value: 6, icon: <LocalShippingOutlinedIcon fontSize='medium' /> }
			case 'EN ROUTE':
				return { value: 50, icon: <LocalShippingIcon fontSize='medium' /> }
			case 'DELIVERED':
				return { value: 100, icon: <CheckCircleOutlineIcon fontSize='medium' isCompleted={100} /> }
			default:
				return { value: 6, icon: <LocalShippingOutlinedIcon fontSize='medium' /> }
		}
	}, [invoiceData])

	const ProgressBarHandler = () => {
		return (
			<>
				<FormItemWrapper>
					<ProgressBarContainer>
						<FillerWapper isCompleted={_deliverStatus?.value}>
							<LabelWapper isCompleted={_deliverStatus?.value}>{_deliverStatus?.icon}</LabelWapper>
						</FillerWapper>
						{_deliverStatus?.value !== 100 && <PanoramaFishEye fontSize='medium' />}
					</ProgressBarContainer>
				</FormItemWrapper>
				<FormItemWrapper>
					<Container>
						<ContainerText>
							<b>{strings('loaded')}</b>
							{invoiceData?.delivery_status && <span>{showDateFormat(new Date(invoiceData?.invoice_date))}</span>}
						</ContainerText>
						<ContainerText>
							<b>{strings('intransit')}</b> {invoiceData?.delivery_status === 'EN ROUTE' && <span>{showDateFormat(new Date())}</span>}
						</ContainerText>
						<ContainerText>
							<b>{strings('delivered')}</b>{' '}
							{invoiceData?.delivery_status === 'DELIVERED' && (
								<span>{invoiceData?.delivery_date && showDateFormat(new Date(invoiceData?.delivery_date))}</span>
							)}
						</ContainerText>
					</Container>
				</FormItemWrapper>
			</>
		)
	}

	const disabledVehicleTracking = () => {
		let regex = /^[0]?[6789]\d{9}$/
		if (!formState.driver_number.value || !regex.test(formState.driver_number.value)) return true
		if (!formState.vehicle_number.value) return true
		return false
	}
	const deleteExportDocs = (filePath, type) => {
		if (!filePath) return
		let apiCall = {
			filePath,
			orderId: order?.order_id,
			type: type,
			invoiceNo: invoiceData?.invoice_number,
			userId: userData.user_id,
		}
		if (supplierPoNumber) {
			const _supplierData = order?.supplier_list.find((item) => item?.supplier_po_number === supplierPoNumber)
			apiCall = { ...apiCall, organisation_id: _supplierData?.supplier?.organisation_id }
		} else {
			const _buyerData = order?.buyer_list?.[0]?.buyer
			apiCall = { ...apiCall, organisation_id: _buyerData?.organisation_id }
		}
		dispatch(showLoader(true))
		deleteOrderDocumentApi(apiCall)
			.then((res) => {
				if (res) {
					let export_documents = invoiceData?.export_documents?.filter((item) => item.document_type !== type)
					dispatch(
						updateDrawerMeta({
							invoiceData: {
								...invoiceData,
								export_documents,
							},
						})
					)
					dispatch(showToast(true, strings('msg_document_deleted_success'), { type: TOAST_TYPE.SUCCESS }))
					update()
				} else {
					dispatch(showToast(true, strings('msg_document_delete_failed'), { type: TOAST_TYPE.ERROR }))
				}
				dispatch(toggleFileViewer(false))
			})
			.catch((err) => {
				console.log(err)
				dispatch(showToast(true, strings('msg_document_delete_failed'), { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}
	const deletePo = (filePath, type) => {
		if (!filePath) return
		let apiCall = {
			filePath,
			orderId: order?.order_id,
			type: documentInvoiceType[type],
			invoiceNo: invoiceData?.invoice_number,
		}
		if (supplierPoNumber) {
			const _supplierData = order?.supplier_list.find((item) => item?.supplier_po_number === supplierPoNumber)
			apiCall = { ...apiCall, organisation_id: _supplierData?.supplier?.organisation_id, userId: _supplierData?.supplier?.user_id }
		} else {
			const _buyerData = order?.buyer_list?.[0]?.buyer
			apiCall = { ...apiCall, organisation_id: _buyerData?.organisation_id, userId: _buyerData?.user_id }
		}
		dispatch(showLoader(true))
		deleteOrderDocumentApi(apiCall)
			.then((res) => {
				if (res) {
					let _invoiceData = { ...invoiceData }
					switch (type) {
						case 'tc':
							_invoiceData = { ..._invoiceData, tc_received: _invoiceData?.tc_received?.filter((item) => item !== filePath) }
							break
						case 'bilti':
							_invoiceData = { ..._invoiceData, bilty_document: null }
							break
						case 'dkt':
							_invoiceData = { ..._invoiceData, dkt_documents: _invoiceData?.dkt_documents?.filter((item) => item !== filePath) }
							break
						case 'ewaybill':
							_invoiceData = { ..._invoiceData, eway_bill_document: null }
							break
						case 'delivery_receipt':
							_invoiceData = { ..._invoiceData, delivery_receipt: null }
							break

						default:
							break
					}
					dispatch(
						updateDrawerMeta({
							invoiceData: {
								..._invoiceData,
							},
						})
					)
					update()

					dispatch(showToast(true, strings('msg_document_deleted_success'), { type: TOAST_TYPE.SUCCESS }))
					dispatch(toggleFileViewer(false))
				} else {
					dispatch(showToast(true, strings('msg_document_delete_failed'), { type: TOAST_TYPE.ERROR }))
					dispatch(toggleFileViewer(false))
				}
			})
			.catch((err) => {
				dispatch(showToast(true, err, { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	const renderDocuments = (data, key) => {
		data = (data ?? []).filter(Boolean)
		if (Array.isArray(data) && data.length) {
			return (
				<DocumentsWrapper>
					<Message>{strings('click_icon_to_download')}</Message>
					{data.map((item, index) => {
						return (
							<DownloadIconWrapper key={`${key}_doc#_${index}`}>
								<DocumentDownload
									link={item}
									type={key}
									updateDocument={updateDocument}
									onDelete={(e) => {
										deletePo(item, key)
									}}
								/>
							</DownloadIconWrapper>
						)
					})}
				</DocumentsWrapper>
			)
		}
		return (
			<DocumentsWrapper>
				<Message>{strings('no_documents')}</Message>
			</DocumentsWrapper>
		)
	}

	const renderExportDocuments = (data, key) => {
		data = (data ?? []).filter(Boolean)
		if (Array.isArray(data) && data.length) {
			return (
				<DocumentsWrapper>
					{data.map((item, index) => {
						return (
							<ExportDownloadIconWrapper key={`${key}_doc#_${index}`}>
								<DocumentDownload
									link={item}
									type={key}
									updateDocument={updateDocument}
									onDelete={(e) => {
										deleteExportDocs(item[0], key)
									}}
								/>
							</ExportDownloadIconWrapper>
						)
					})}
				</DocumentsWrapper>
			)
		}

		return (
			<DocumentsWrapper>
				<Message>{strings('no_documents')}</Message>
			</DocumentsWrapper>
		)
	}

	const renderOrderItems = () => {
		return formState.order_items.map((item, index) => (
			<FormWrapper key={index}>
				<FormItemWrapper isMargin IsFlex={order?.platform !== 'SCRAP'} isFlexCol IsRejectWieght={order?.platform === 'SCRAP'}>
					<Input
						type='number'
						label={`${item.product_name} (kg)`}
						value={item.serviced_weight}
						placeholder={strings('placeholder_enter_serviced_weight_kg')}
						onChange={onChangeOrderItem('serviced_weight', item?.product_id)}
					/>
					{order?.platform === 'SCRAP' && (
						<Input
							type='number'
							label={formState.rejected_weight?.label}
							disabled={formState.rejected_weight?.disabled}
							value={formState.rejected_weight?.value}
							placeholder={formState.rejected_weight?.placeholder}
							onChange={onChange('rejected_weight')}
							required={formState.rejected_weight?.required}
						/>
					)}

					<Input
						type='number'
						label={'Quote Per Kg'}
						value={item.price_per_kg}
						placeholder={strings(['enter', 'price_per_kg'])}
						onChange={onChangeOrderItem('price_per_kg', item?.product_id)}
					/>
				</FormItemWrapper>
			</FormWrapper>
		))
	}

	const viewInvoiceHandler = (file) => {
		dispatch(toggleFileViewer(true, { files: file }))
	}

	const vehicleTrackingHandler = () => (
		<VehicleTrackingWapper>
			{invoiceData?.trip_id ? (
				<>
					<ButtonTrip
						type='primary'
						margin={`0px 5px 0px 0px`}
						xs
						uppercase
						disabled={!formState.driver_number.dirty && !formState.vehicle_number.dirty}
						onClick={updateVehicleTrackingHandler}
					>
						<EditIcon fontSize='medium' />
					</ButtonTrip>
					<ButtonTrip
						type='secondary'
						variant='destructive'
						margin={`0px 5px 0px 0px`}
						xs
						uppercase
						disabled={invoiceData?.delivery_status === 'DELIVERED'}
						onClick={stopVehicleTrackingHandler}
					>
						<CancelPresentationIcon fontSize='medium' />
					</ButtonTrip>
				</>
			) : (
				<ButtonTrip type='primary' xs uppercase disabled={disabledVehicleTracking()} onClick={startVehicleTrackingHandler}>
					<SubdirectoryArrowLeft fontSize='small' />
				</ButtonTrip>
			)}
		</VehicleTrackingWapper>
	)

	return (
		<DrawerBodyWrapper>
			{ProgressBarHandler()}
			<FormWrapper>
				<FormItemWrapper isMargin>
					<Input
						type='text'
						label={formState.invoice_number?.label}
						disabled={formState.invoice_number?.disabled}
						value={formState.invoice_number?.value}
						placeholder={formState.invoice_number?.placeholder}
						onChange={onChange('invoice_number')}
						required={formState.invoice_number?.required}
					/>
					<Calendar
						label={formState.invoice_date?.label}
						disabled={formState.invoice_date?.disabled}
						value={formState.invoice_date?.value}
						placeholder={formState.invoice_date?.placeholder}
						onChange={onChange('invoice_date')}
						required={formState.invoice_date?.required}
					/>
				</FormItemWrapper>
				<FormItemWrapper isMargin>
					<Input
						type='number'
						label={formState.invoice_amount?.label}
						disabled={formState.invoice_amount?.disabled}
						value={formState.invoice_amount?.value}
						placeholder={formState.invoice_amount?.placeholder}
						onChange={onChange('invoice_amount')}
						required={formState.invoice_amount?.required}
					/>
					<Select
						primaryKey={formState.delivery_status?.displayKey}
						displayKey={formState.delivery_status?.displayKey}
						data={formState.delivery_status?.data}
						label={formState.delivery_status?.label}
						disabled={formState.delivery_status?.disabled}
						value={formState.delivery_status?.value}
						placeholder={formState.delivery_status?.placeholder}
						onChange={onChange('delivery_status')}
						required={formState.delivery_status?.required}
					/>
				</FormItemWrapper>
				<FormItemWrapper isMargin>
					<Input
						label={formState.gps_link?.label}
						disabled={formState.gps_link?.disabled}
						value={formState.gps_link?.value}
						placeholder={formState.gps_link?.placeholder}
						onChange={onChange('gps_link')}
						required={formState.gps_link?.required}
						pattern='https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}'
						errorMap={{
							patternMismatch: strings('enter_valid_url_link'),
						}}
					/>
					<OpenInNewIcon
						fontSize='medium'
						onClick={(e) => {
							TrackingHandler(e, formState.gps_link?.value)
						}}
					/>
				</FormItemWrapper>
				<SubSectionHeading>{strings('vehicle', 'details')}</SubSectionHeading>
				<FormItemWrapper isMargin isFlexCol>
					<Input
						type='text'
						label={formState.driver_number?.label}
						disabled={formState.driver_number?.disabled}
						value={formState.driver_number?.value}
						placeholder={formState.driver_number?.placeholder}
						onChange={onChange('driver_number')}
						pattern='[6789][0-9]{9}'
						errorMap={{
							patternMismatch: strings('pls_enter_valid_mobile_num'),
						}}
					/>
					<Input
						type='text'
						label={formState.vehicle_number?.label}
						disabled={formState.vehicle_number?.disabled}
						value={formState.vehicle_number?.value}
						placeholder={formState.vehicle_number?.placeholder}
						onChange={onChange('vehicle_number')}
					/>
					{!supplierPoNumber && vehicleTrackingHandler()}
				</FormItemWrapper>
				<SubSectionHeading>{strings('enter_serviced_weight_sku')}</SubSectionHeading>
				{renderOrderItems()}
				<>
					<SubSectionHeading>{strings('other', 'charges')}</SubSectionHeading>
					{formState.other_charges?.value && Object.entries(formState.other_charges.value).length > 0 && (
						<ChargesWrapper>
							{Object.entries(formState?.other_charges?.value).map(([key, value], index) => {
								return (
									<ChargeListWrapper>
										<div>{OTHER_CHARGES_INVOICE?.find((item) => item.key === key)?.label}</div>
										<div>{displayAmount(value)}</div>
										<Button
											xs
											small
											variant='destructive'
											type='secondary'
											onClick={() => {
												let _tempList = formState?.other_charges?.value
												delete _tempList[key]
												setFormState((_s) => ({ ..._s, _tempList }))
											}}
										>
											<Delete />
										</Button>
									</ChargeListWrapper>
								)
							})}
						</ChargesWrapper>
					)}
					<FormItemWrapper isFlexCol>
						<ChargeListWrapper>
							<Select
								label={strings(['other', 'charge', 'type'])}
								placeholder={strings(['select', 'other', 'charge', 'type'])}
								primaryKey='key'
								displayKey='label'
								value={otherChargeType ?? {}}
								data={OTHER_CHARGES_INVOICE}
								onChange={(value) => {
									setOtherChargetype(value)
								}}
							/>
							<Input
								type='number'
								label={strings('charge', 'amount')}
								placeholder={strings(['enter', 'charge', 'amount'])}
								value={otherChargeValue ?? ''}
								onChange={(value) => {
									setOtherChargeValue(value)
								}}
								disbaled={!otherChargeType}
							/>
						</ChargeListWrapper>
						<Button
							small
							xs
							margin={`0 0 0 ${Spacings.SPACING_3B}`}
							onClick={() => {
								let _otherChargeList = formState?.other_charges?.value
								_otherChargeList = { ..._otherChargeList, [otherChargeType?.key]: otherChargeValue }

								setFormState((_s) => ({
									..._s,
									['other_charges']: {
										..._s['other_charges'],
										value: _otherChargeList,
									},
								}))
								setOtherChargeValue(null)
								setOtherChargetype({})
							}}
							disabled={!otherChargeType || !otherChargeValue}
						>
							{strings('add')}
						</Button>
					</FormItemWrapper>
				</>
				<FormItemWrapper>
					<ActionButton type='primary' xs uppercase disabled={disabledUpdateButton} onClick={updateInvoiceInfo}>
						{strings('update')}
					</ActionButton>
				</FormItemWrapper>
			</FormWrapper>
			{manageOrderType === 'enquiry' && supplierPoNumber && (
				<>
					<CustomSectionHeader>{strings('stock', 'photo')}</CustomSectionHeader>
					<FormItemWrapper isStock>
						<ImageHandler images={invoiceData?.product_photos} handler={uploadStockPhotoHandler} />
					</FormItemWrapper>
				</>
			)}

			<CustomSectionHeader>{strings('test_certificate')} </CustomSectionHeader>
			<FormItemWrapper>{renderDocuments(invoiceData?.tc_received, 'tc')}</FormItemWrapper>
			<FormItemWrapper>
				<UploadMultipleFile
					label={strings('upload', 'test_certificate')}
					xs
					uppercase
					onChange={(file) => uploadMultipleDoc(file, 'tc', 'tc_received')}
				/>
			</FormItemWrapper>
			<CustomSectionHeader>{strings('bilti')}</CustomSectionHeader>
			<FormItemWrapper>{renderDocuments([invoiceData?.bilty_document], 'bilti')}</FormItemWrapper>
			<FormItemWrapper>
				<DocUploadButton
					label={strings('upload', 'bilti')}
					xs
					uppercase
					onChange={(file) => uploadMultipleDoc(file, 'bilti', 'bilty_document')}
				/>
			</FormItemWrapper>

			<CustomSectionHeader>{strings('ewaybill')}</CustomSectionHeader>
			<FormItemWrapper>{renderDocuments([invoiceData?.eway_bill_document], 'ewaybill')}</FormItemWrapper>
			<FormItemWrapper>
				<DocUploadButton
					label={strings('upload', 'ewaybill')}
					xs
					uppercase
					onChange={(file) => uploadMultipleDoc(file, 'ewaybill', 'eway_bill_document')}
				/>
			</FormItemWrapper>

			<CustomSectionHeader>{strings('dharamkata_slip')}</CustomSectionHeader>
			<FormItemWrapper>{renderDocuments(invoiceData?.dkt_documents, 'dkt')}</FormItemWrapper>
			<FormItemWrapper>
				<UploadMultipleFile
					label={strings('upload', 'dharamkata_slip')}
					xs
					uppercase
					onChange={(file) => uploadMultipleDoc(file, 'dkt', 'dkt_documents')}
				/>
			</FormItemWrapper>
			{!supplierPoNumber && (
				<>
					<CustomSectionHeader>{strings('delivery_receipt')}</CustomSectionHeader>
					<FormItemWrapper>{renderDocuments([invoiceData?.delivery_receipt], 'delivery_receipt')}</FormItemWrapper>
					<FormItemWrapper>
						<DocUploadButton
							label={strings('upload', 'delivery_receipt')}
							xs
							uppercase
							onChange={(file) => uploadMultipleDoc(file, 'delivery_receipt', 'delivery_receipt')}
						/>
					</FormItemWrapper>
				</>
			)}
			{!supplierPoNumber && (
				<>
					<CustomSectionHeader>{strings(['export', 'documents'])}</CustomSectionHeader>
					<ExportFormItemWrapper isMargin>
						<Select
							primaryKey={'aliasname'}
							displayKey={'aliasname'}
							data={docTypeAll.filter((item) => {
								return !invoiceData?.export_documents?.some((doc) => doc.document_type === item.name)
							})}
							openBelow={false}
							label={strings(['upload', 'export', 'document'])}
							disabled={false}
							value={docTypeAll?.find((item) => item.name === docType) || {}}
							placeholder={strings(['enter', 'document', 'type'])}
							onChange={(e) => {
								setDocType(e.name)
							}}
							required={false}
							small
						/>
						<div>
							<ExportDocUploadButton
								label={strings('upload', 'document')}
								xs
								uppercase
								disabled={docType == null || docType == '' ? true : false}
								onChange={(file) => {
									uploadExportDocuments(docType, file)
								}}
							/>
						</div>
					</ExportFormItemWrapper>
					{invoiceData?.export_documents
						?.filter((item) =>
							userData.role === MB_ROLES.SUPER_ADMIN || userData.role === MB_ROLES.ADMIN ? true : item.only_admin == false
						)
						.map((item) => {
							return (
								<ExportDocFormItemWrapper>
									<ExportDocName>{docTypeAll.find((val) => item.document_type == val.name).aliasname}</ExportDocName>
									<ExportDocItem>{renderExportDocuments([item?.documents], item.document_type)}</ExportDocItem>
								</ExportDocFormItemWrapper>
							)
						})}
				</>
			)}
		</DrawerBodyWrapper>
	)
})

export default AdminEditInvoice
