import React from 'react'
import styled from 'styled-components'
import { strings } from '@constants'
import { DetailsWrapper, GridListWrapper, PillWapper } from '../../styles'
import { Pill, SectionHeader } from '@base'
import { Spacings } from '@styles'

const AlertProgress = styled.div`
	margin: 5px;
	width: 160px;
	height: 80px;
	position: relative;
	color: #fff;
	font-size: 22px;
	font-weight: 600;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	overflow: hidden;
	box-sizing: border-box;
	&:before {
		content: '';
		width: 160px;
		height: 80px;
		border: 30px solid rgba(0, 0, 0, 0.15);
		border-bottom: none;
		position: absolute;
		box-sizing: border-box;
		transform-origin: 50% 0%;
		border-radius: 300px 300px 0 0;
		left: 0;
		top: 0;
	}
	.graph {
		width: 160px;
		height: 80px;
		border: 30px solid #e65100;
		border-top: none;
		position: absolute;
		transform-origin: 50% 0% 0;
		border-radius: 0 0 300px 300px;
		left: 0;
		top: 100%;
		z-index: 5;
		animation: 1s fillGraphAnimation ease-in;
		transform: ${({ percentage }) => `rotate(calc(1deg*(${percentage})*1.8))`};
		box-sizing: border-box;

		&:after {
			counter-reset: ${({ percentage }) => `varible ${percentage}`};
			background: blue;
			border-radius: 2px;
			color: #fff;
			font-weight: 200;
			font-size: 12px;
			height: 20px;
			padding: 3px 5px;
			top: 0px;
			position: absolute;
			left: 0;
			transform: ${({ percentage }) => `rotate(calc(-1deg*${percentage})*1.8) translate(-30px ,0px)`};
			transition: 0.2s ease-in;
			transform-origin: 0 50% 0;
			opacity: 0;
		}
	}
	.percentage-text {
		z-index: 10;
		color: #e65100;
	}
	@keyframes fillAnimation {
		0% {
			transform: rotate(-45deg);
		}
		50% {
			transform: rotate(135deg);
		}
	}

	@keyframes fillGraphAnimation {
		0% {
			transform: rotate(0deg);
		}
		50% {
			transform: rotate(180deg);
		}
	}
`

const GraphWrapper = styled(DetailsWrapper)`
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: space-between;
	align-items: center;
`

const CustomPillWrapper = styled(PillWapper)`
	display: flex;
	align-content: flex-start;
`

const LimitsFooter = styled.div`
	display: flex;
	flex-direction: row;
	width: 155px;
	color: #e65100;

	padding-left: ${Spacings.SPACING_4B};
	justify-content: space-between;
`

const Alerts = ({ financialData }) => {
	const alert_graph = financialData?.alerts?.length ?? 0
	return (
		<>
			<DetailsWrapper>
				<SectionHeader>{strings('alerts')}</SectionHeader>
				<GridListWrapper itemsPerRow={[1, 2, 2]}>
					<GraphWrapper>
						<AlertProgress percentage={alert_graph * 25}>
							<div className='graph'></div>
							<div className='percentage-text'>{`${alert_graph}`}</div>
						</AlertProgress>
						<LimitsFooter>
							<span>0</span>
							<span>4</span>
						</LimitsFooter>
					</GraphWrapper>
					<CustomPillWrapper>
						{financialData?.alerts?.map((item, index) => (
							<Pill value={item} margin={`${Spacings.SPACING_1B}`} key={index} />
						))}
					</CustomPillWrapper>
				</GridListWrapper>
			</DetailsWrapper>
		</>
	)
}

export default Alerts
