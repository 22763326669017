import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Button, DrawerBodyWrapper, DrawerFooterWrapper, TOAST_TYPE } from '@base'
import { useDispatch } from 'react-redux'
import { enquiryInputType, strings } from '@constants'

import { getProductByCategory, weightConvertorApi } from '@data'
import { showLoader, showToast } from '@data/state/action'
import { toggleDrawer } from '@data/state/action/root'
import EnquiryItemForm from './EnquiryItemForm'
import { Devices, Spacings } from '@styles'
import { updateNewEnquiry } from './CreateEnquiry/state/actions'

const ActionButton = styled(Button)`
	margin-left: ${Spacings.SPACING_4B};
	max-width: 14rem;

	&:last-of-type {
		margin-left: 0;
	}

	// text-transform: uppercase;
	flex-grow: 1;

	@media ${Devices.tablet} {
		flex-grow: 0;
	}

	margin: ${({ margin }) => margin};
`

const initialFormState = {
	category: {
		label: strings('category'),
		disabled: false,
		readOnly: false,
		displayKey: 'category_name',
		primaryKey: 'category_id',
		placeholder: strings('select_a_category'),
		data: [],
		value: '',
		dirty: false,
		shouldFetchProducts: { flag: true },
	},
	product: {
		label: strings('product'),
		disabled: false,
		readOnly: false,
		displayKey: 'product_name',
		primaryKey: 'product_id',
		placeholder: strings('search_product'),
		data: [],
		value: '',
		dirty: false,
	},
	requiredWeight: {
		label: strings(['weight', '(', 'mt', ')']),
		disabled: false,
		readOnly: false,
		value: '',
		dirty: false,
	},
	requiredLength: {
		label: strings('length'),
		disabled: false,
		readOnly: false,
		value: '',
		dirty: false,
	},
	requiredPieces: {
		label: strings('pieces'),
		disabled: false,
		readOnly: false,
		value: '',
		dirty: false,
	},
	targetPrice: {
		label: strings('target', 'price'),
		disabled: false,
		readOnly: false,
		value: '',
		dirty: false,
	},
	brands: {
		label: strings('brands'),
		disabled: false,
		readOnly: false,
		displayKey: 'name',
		primaryKey: 'id',
		placeholder: strings('select_brands'),
		data: [],
		value: [],
		dirty: false,
	},
	message: {
		label: strings('message'),
		disabled: false,
		readOnly: false,
		value: '',
		dirty: false,
	},
	tcRequired: {
		label: strings('tc_reqd'),
		disabled: false,
		value: true,
		dirty: false,
	},
	inputType: {
		label: strings('type'),
		disabled: false,
		value: '',
		dirty: false,
	},
}

const deriveFormStateFromItemData = (oldState = initialFormState, data, editable = true, selectedbrands = [], checkScrap) => {
	const derivedState = { ...oldState }

	derivedState.category.value = { category_id: data.category_id, category_name: data.category_name }
	derivedState.category.disabled = checkScrap?.category_id?.includes('scrap') ? true : !editable
	derivedState.category.readOnly = !editable

	derivedState.product.value = { product_id: data.product_id, product_name: data.product_name }
	derivedState.product.disabled = checkScrap?.category_id?.includes('scrap') ? true : !editable
	derivedState.product.readOnly = !editable

	derivedState.requiredWeight.value = data.required_weight
	derivedState.requiredWeight.disabled = !editable
	derivedState.requiredWeight.readOnly = !editable

	derivedState.requiredPieces.value = data.required_pieces
	derivedState.requiredPieces.disabled = !editable
	derivedState.requiredPieces.readOnly = !editable

	derivedState.requiredLength.value = data.required_length
	derivedState.requiredLength.disabled = !editable
	derivedState.requiredLength.readOnly = !editable

	derivedState.inputType.value = data.input_type
	derivedState.inputType.disabled = !editable
	derivedState.inputType.readOnly = !editable

	derivedState.targetPrice.value = data.target_price || ''
	derivedState.targetPrice.disabled = !editable
	derivedState.targetPrice.readOnly = !editable

	derivedState.brands.value = selectedbrands
	derivedState.brands.disabled = !editable
	derivedState.brands.readOnly = !editable
	derivedState.message.value = data.message ?? ''
	derivedState.message.disabled = !editable
	derivedState.message.readOnly = !editable
	derivedState.tcRequired.value = data.tc_required ?? true
	derivedState.tcRequired.disabled = !editable

	return derivedState
}

const CreateItem = ({
	itemId,
	editable = true,
	getState,
	addNewItemToEnquiryCallback,
	deleteEnquiryItemCallback,
	updateEnquiryItemCallback,
	updateplatformcallback,
	defualtCategoryId = null,
	dispatch,
	chooseEnquiryPhoto,
	activeCommodity,
}) => {
	const globalDispatch = useDispatch()
	const categories = getState().categories
	const brands = getState().brands
	const enquiryItems = getState().newEnquiry.get(['enquiry_items'])
	const checkTmtItem = ['ms_tmt_bars']
	const checkScrapItem = ['scrap', 'lead_scrap']
	const defaultBrand = { name: 'ANY', id: 'any' }
	const lastCategoryProductsFetched = useRef()
	const [formState, setFormState] = useState(initialFormState)

	const item = enquiryItems?.find((_item) => _item.get('id') === itemId)?.toJS() ?? {}
	const checkScrap = enquiryItems?.find((_item) => _item.get('category_id')?.includes('scrap'))?.toJS() ?? {}
	const updateFormState = (key, updates) => {
		setFormState((_oldState) => ({
			..._oldState,
			[key]: {
				..._oldState[key],
				...updates,
			},
		}))
	}
	useEffect(() => {
		lastCategoryProductsFetched.current = undefined
		let selectedbrands = item?.brands?.length > 0 ? brands.data.filter((cls) => item?.brands.includes(cls.name)) : []
		setFormState((_oldState) => deriveFormStateFromItemData(_oldState, item, !!editable, selectedbrands, checkScrap))
	}, [editable])

	const updateCategoryState = () => {
		if (categories?.data?.data) {
			let data = categories?.data?.data
			if (enquiryItems?.toJS().length && enquiryItems?.some((item) => !item.category_id?.includes('scrap'))) {
				data = data.filter((item) => !item?.category_id?.includes('scrap'))
			}
			const updates = { data }
			if (item?.category_id) {
				updates.value = data.find((_item) => _item.category_id === item.category_id)
				updates.shouldFetchProducts = { flag: true }
			}
			updateFormState('category', updates)
		}
	}

	useEffect(() => {
		updateCategoryState()
	}, [categories, editable])

	useEffect(() => {
		if (brands?.data) {
			if (!formState.brands.value) {
				updateFormState('brands', { data: brands?.data, value: brands?.data })
			} else {
				updateFormState('brands', { data: brands?.data })
			}
		}
	}, [brands])

	useEffect(() => {
		if (formState.category.dirty) {
			updateFormState('product', { value: '', data: [], dirty: false })
		}
		const categoryId = formState.category.value?.category_id
		if (categoryId && categoryId !== lastCategoryProductsFetched.current) {
			lastCategoryProductsFetched.current = categoryId
			globalDispatch(showLoader(true))
			getProductByCategory(categoryId)
				.then((data) => {
					const updates = { data, value: '' }
					if (item?.product_id && categoryId === item?.category_id) {
						updates.value = data?.find((_item) => item.product_id === _item.product_id) || formState.product.value
					}
					updateFormState('product', updates)
				})
				.finally((e) => {
					globalDispatch(showLoader(false))
				})
		}
	}, [formState.category.value, formState.category.shouldFetchProducts])

	useEffect(() => {
		if (formState.product.dirty) {
			const selectedProduct = formState.product.value

			if (selectedProduct?.product_id === item?.product_id) {
				return
			}

			const sameProductItemsLength = enquiryItems?.filter((_item) => _item.get('product_id') === selectedProduct?.product_id).size
			if (sameProductItemsLength > 0) {
				globalDispatch(showToast(true, strings('msg_item_already_added'), { type: TOAST_TYPE.ERROR }))
				updateFormState('product', { value: '' })
			}
		}
	}, [formState.product.value])

	useEffect(() => {
		defualtCategoryId &&
			updateFormState('category', { value: categories?.data?.data?.find((_c) => _c.category_id === defualtCategoryId), dirty: true })
	}, [defualtCategoryId])

	const formStateToEnquiryItem = () => {
		return {
			category_id: formState.category.value.category_id,
			category_name: formState.category.value.category_name,
			product_id: formState.product.value.product_id,
			product_name: formState.product.value?.product_name,
			required_weight: formState.requiredWeight.value === '' ? '' : parseFloat(formState.requiredWeight.value),
			required_length: formState.requiredLength.value === '' ? '' : parseFloat(formState.requiredLength.value),
			required_pieces: formState.requiredPieces === '' ? '' : parseFloat(formState.requiredPieces.value),
			input_type: formState.inputType.value,
			target_price: formState.targetPrice.value,
			brands: formState.brands.value.map((a) => a.name),
			message: formState.message.value,
			tc_required: formState.tcRequired.value,
			is_unknown_product: formState.product.value.is_new_item,
		}
	}

	const addUpdateHandler = (isUpdate = false) => {
		let _enquiryItemData = formStateToEnquiryItem()

		if (formState.inputType.value !== enquiryInputType.WEIGHT) {
			weightConvertorApi(formState?.category?.value?.category_id, formState?.requiredLength?.value, formState?.requiredPieces?.value, {
				...formState?.product?.value?.product_attributes,
			})
				.then((response) => {
					_enquiryItemData.required_weight = response?.weight
				})
				.catch((err) => {
					console.log(err)
				})
				.finally(() => {
					if (isUpdate) {
						updateHandler(_enquiryItemData)
					} else {
						addNewItemHandler(_enquiryItemData)
					}
				})
		} else {
			if (isUpdate) {
				updateHandler(_enquiryItemData)
			} else {
				addNewItemHandler(_enquiryItemData)
			}
		}
	}
	const addNewItemHandler = (newEnquiryItemData) => {
		if (newEnquiryItemData?.category_id?.includes('scrap')) {
			updateplatformcallback('SCRAP')
		}

		if (checkScrapItem?.some((val) => formState?.category?.value?.category_id?.includes(val))) {
			formState?.product?.value.filter((item) => {
				let _formData = formStateToEnquiryItem()
				_formData.product_id = item.product_id
				_formData.product_name = item.product_name
				_formData.required_weight = (newEnquiryItemData?.required_weight / formState?.product?.value?.length).toFixed(2)
				_formData.target_price = newEnquiryItemData?.target_price / formState?.product?.value?.length
				if (!enquiryItems.toJS()?.find((item2) => item.product_id === item2.product_id)) {
					addNewItemToEnquiryCallback(_formData)
					chooseEnquiryPhoto &&
						dispatch(
							updateNewEnquiry({
								enquiry_photo_id: chooseEnquiryPhoto?.enquiry_photo_id,
							})
						)
				}
			})
			globalDispatch(toggleDrawer(false))

			return
		}
		if (checkTmtItem.includes(newEnquiryItemData.category_id)) {
			if (formState?.product?.tmtProduct === undefined) {
				globalDispatch(showToast(true, strings('msg_wrong_value'), { type: TOAST_TYPE.ERROR }))
				return
			}

			formState?.product?.tmtProduct.filter((item) => {
				let _formData = formStateToEnquiryItem()
				if (item?.required_weight !== '') {
					_formData.product_id = item.product_id
					_formData.product_name = item.product_name
					_formData.required_weight = item.required_weight * 1000
					_formData.target_price = item.target_price
					if (!enquiryItems.toJS()?.find((item2) => item.product_id === item2.product_id)) {
						addNewItemToEnquiryCallback(_formData)
						chooseEnquiryPhoto &&
							dispatch(
								updateNewEnquiry({
									enquiry_photo_id: chooseEnquiryPhoto?.enquiry_photo_id,
								})
							)
					}
				}
			})
			globalDispatch(toggleDrawer(false))

			return
		}
		if (
			(newEnquiryItemData.input_type === enquiryInputType.WEIGHT && newEnquiryItemData.required_weight <= 0) ||
			(newEnquiryItemData.input_type === enquiryInputType.LENGTH && newEnquiryItemData.required_length <= 0) ||
			(newEnquiryItemData.input_type === enquiryInputType.PIECE && newEnquiryItemData.required_pieces <= 0)
		) {
			globalDispatch(showToast(true, strings('msg_wrong_value'), { type: TOAST_TYPE.ERROR }))
			return
		}

		if (newEnquiryItemData) {
			chooseEnquiryPhoto && dispatch(updateNewEnquiry({ enquiry_photo_id: chooseEnquiryPhoto?.enquiry_photo_id }))
			addNewItemToEnquiryCallback(newEnquiryItemData)
			globalDispatch(toggleDrawer(false))
			return
		}
	}

	const cancelEditHandler = () => {
		globalDispatch(toggleDrawer(false))
	}

	const updateHandler = (updatedEnquiryItemData) => {
		if (updatedEnquiryItemData.required_weight <= 0) {
			globalDispatch(showToast(true, strings('msg_wrong_value'), { type: TOAST_TYPE.ERROR }))
			return
		}
		if (updatedEnquiryItemData) {
			updateEnquiryItemCallback(itemId, updatedEnquiryItemData)
			globalDispatch(toggleDrawer(false))
		}
	}

	const deleteButtonHandler = () => {
		deleteEnquiryItemCallback(itemId)
		globalDispatch(toggleDrawer(false))
	}

	const addDisableButton = () => {
		if (checkTmtItem.includes(formState.category.value?.category_id)) {
			if (!formState.category.value?.category_id || !formState.brands.value.length) return true
			if (formState?.product?.tmtProduct?.length) {
				let reg = /^[\d]+(?:\.\d{1,3})?$/
				let emptyCheck = formState?.product?.tmtProduct?.some((item) => item?.required_weight)
				if (!emptyCheck) return true
				return formState?.product?.tmtProduct?.find((item) => item?.required_weight && !reg.test(item?.required_weight))
			}
			if (formState?.product?.data.length && !formState?.product?.tmtProduct?.length) return true
		}

		if (checkScrapItem?.some((val) => formState?.category?.value?.category_id?.includes(val))) {
			if (!formState.product.value.length || !formState.requiredWeight.value) return true
			return false
		}
		return (
			!formState.category.value?.category_id ||
			!formState.product.value?.product_id ||
			!(
				(formState.inputType.value === enquiryInputType.WEIGHT &&
					formState.requiredWeight.value &&
					parseFloat(formState.requiredWeight.value) === Math.floor(parseFloat(formState.requiredWeight.value))) ||
				(formState.inputType.value === enquiryInputType.PIECE && formState.requiredPieces.value) ||
				(formState.inputType.value === enquiryInputType.LENGTH && formState.requiredLength.value)
			) ||
			!formState.brands.value.length
		)
	}

	const updatedisableButton = () => {
		if (checkTmtItem.includes(formState.category.value?.category_id))
			return !formState.category.value?.category_id || !formState.brands.value.length

		return (
			!formState.category.value?.category_id ||
			!(formState.product.data.length && formState.product.value?.product_id) ||
			!(
				(formState.inputType.value === enquiryInputType.WEIGHT &&
					formState.requiredWeight.value &&
					parseFloat(formState.requiredWeight.value) === Math.floor(parseFloat(formState.requiredWeight.value))) ||
				(formState.inputType.value === enquiryInputType.PIECE && formState.requiredPieces.value) ||
				(formState.inputType.value === enquiryInputType.LENGTH && formState.requiredLength.value)
			) ||
			!formState.brands.value.length
		)
	}

	const renderButtons = () => {
		if (!itemId && editable) {
			return (
				<ActionButton small type='primary' disabled={addDisableButton()} onClick={() => addUpdateHandler(false)}>
					{strings('add_item')}
				</ActionButton>
			)
		} else if (itemId && editable) {
			return (
				<>
					<ActionButton small disabled={updatedisableButton()} type='primary' onClick={() => addUpdateHandler(true)}>
						{strings('update')}
					</ActionButton>
					<ActionButton small type='tertiary' onClick={cancelEditHandler}>
						{strings('cancel')}
					</ActionButton>
					<ActionButton margin={`0 auto 0 0`} small type='primary' variant='destructive' onClick={deleteButtonHandler}>
						{strings('delete')}
					</ActionButton>
				</>
			)
		}
	}

	return (
		<>
			<DrawerBodyWrapper>
				<EnquiryItemForm
					enquiryItems={enquiryItems.toJS()}
					itemId={itemId}
					state={formState}
					updateState={updateFormState}
					defaultBrand={defaultBrand}
					defualtCategoryId={defualtCategoryId}
					checkTmtItem={checkTmtItem}
					activeCommodity={activeCommodity}
				/>
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>{renderButtons()}</DrawerFooterWrapper>
		</>
	)
}

export default CreateItem
